<template>
  <div class="home container">

    
    
    <div class="container grid">
      
      <hgroup>
        <h2>No es sobre el wow es sobre el aww</h2>
        <span>Curaduría de Rocío Rivadeneyra. 
          <br>
          <br>Participan: Florencia Méttola, Nando Migueles, Natalia Segré, Rolo Juárez, Sofia Casadey. 
          <br>Noviembre 2022.</span>
      </hgroup>
      
    </div>

    <div class="container" style="padding-bottom: 10vh;">

      <img src="../assets/wow10.jpg" alt="">
      <img src="../assets/wow2.jpg" alt="">
      <img src="../assets/wow3.jpg" alt="">

      <p>
        <br>
        <br>
        <i>
          Texto de sala:
        </i>
        <br>
        <br>
        
        En este collage de caricia y fantasía siento las emociones que alguien emite cerca de mi, se lo que sienten los demás por la presión del aire sobre la piel.
        <br>
        Haría de toda la habitación una torta de frutillas gigante, me cansa tanto marco teórico, además es siempre poco lo que hay que decir.
        <br>
        
        Mientras... ser amable y permitirse la experiencia del asombro originario.
        <br>
        
        Este es un poema tierno para que pienses en mi.
        <br>
        
        Ya veremos cómo se resuelven los problemas más grandes, gracias por venir.
        <br>
        
        No habría podido sin vos.
        <br>
        <br>


        <b>Rocío Rivadeneyra</b>

        
      </p>

      <img src="../assets/wow4.jpg" alt="">
      <img src="../assets/wow5.jpg" alt="">
      <img src="../assets/wow6.jpg" alt="">
      <img src="../assets/wow7.jpg" alt="">
      <img src="../assets/wow8.jpg" alt="">
      
      <img src="../assets/wow11.jpg" alt="">
      <img src="../assets/wow12.jpg" alt="">
      <img src="../assets/wow1.jpg" alt="">
      <img src="../assets/wowFlyer.jpg" alt="">
      
      

      <br>
      <br>
      
    </div>
      
    
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>


<style scoped>

p {
  font-size: 0.9rem;
  text-align: left;
}

img {
  padding-bottom: 10px;
  max-height: 70vh;
}

router-link:hover * {
  text-decoration: none;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}
.pos {
  position: relative;
  overflow: hidden;
  padding: 3vw;
  height: auto;
  align-items: flex-end;
  align-content: flex-start;
  margin-bottom: 3vh;
}


section {
  padding: 5vw 20vw;
  background-color: #ddd;
}


.pos:hover {

  cursor: pointer;
}
.pos:hover img{
  transform: scale(1.04);
}



h3, .light {
  color: white;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
  text-align: left;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.5rem;
}

.light {
  
  font-size: 8pt;
  line-height: 0.8rem;
}


</style>