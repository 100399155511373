<template>
  <div class="home container">

    
    
    <div class="container" style="text-align: left;">
      
      <p>
        Consultorio es un espacio independiente para el arte actual en San Miguel de Tucumán, ubicado en San Lorenzo 634. Actualmente está co-dirigido por Diego Gelatti, Rocío Rivadeneyra y Javier Rodríguez. Al momento de escribir esto nos encontramos tramitando el registro legal de la entidad como una cooperativa de trabajo, con el apoyo de TAViT (Trabajadores de artes visuales de Tucumán)
        <br>
        <br>

        Desde 2022 organizamos exposiciones individuales y colectivas con un enfoque en arte contemporáneo, siguiendo un programa que busca impulsar a agentes emergentes de las provincias, propiciando el intercambio entre diferentes escenas locales. Además, brindamos un espacio necesario para actividades como residencias, capacitaciones y talleres abiertos en el corazón de la ciudad.

      </p>

      <hr>

      <h6>Equipo de dirección</h6>

      <div class="grid">
        
        <div>
          <a href="https://www.instagram.com/diegogelatti/" target="_blank"><span>⁂ Diego Gelatti</span></a>
          <p>Tucumán, Argentina, 1997. Vive y trabaja en Tafí Viejo, Tucumán.

            Licenciado en artes plásticas por la Universidad Nacional de Tucumán (2022). Desde 2017 participa como artista visual en diversos programas de formación y talleres, y espacios de exhibición regionales, nacionales e internacionales. Algunas distinciones, becas y residencias incluyen: Beca “sostener cultura II”, Fondo nacional de las artes (2020); Selección para el 110 Salón Nacional de Artes Visuales (2022); Residencia Raíces, coordinada por Policarpo Aconquija (2021); Residencia Satélite, en Córdoba capital (2023). Durante 2021 y 2022 recibe dos medias becas para participar del XIII Programa de Artistas de la Universidad Torcuato Di Tella.  Actualmente co-dirige Consultorio, espacio para las artes actuales en San Miguel de Tucumán. www.soportable.xyz</p>
        </div>

        
        <div>
          <a href="https://www.instagram.com/rocio.rivadeneyra/" target="_blank"><span>⁂ Rocío Rivadeneyra</span></a>
          <p>Tucumán, Argentina, 1994. Vive y trabaja en San Miguel de Tucumán, Tucumán. 

            Curadora, artista visual e investigadora. Estudiante avanzada de la Licenciatura en artes visuales de la Facultad de Artes de la Universidad Nacional de Tucumán. Actual codirectora del espacio de gestión independiente Consultorio. Desempeña la formación en RRHH en la cátedra Escultura I, II y III de la Facultad de Artes de Tucumán, 2020. Becada del Programa de formación para Curadores en PAC (Galería Gachi Prieto) 2021/2022. Seminario “Asamblea Permanente” a cargo de Carla Barbero, Universidad Torcuato Di Tella, 2022.

            Algunas curadurías y gestiones de muestras: “Voluntad Formal”, Centro Cultural Eugenio Flavio Virla, 2020, Tucuman (proyecto ganador de la convocatoria Impulsar Cultura, Extensión de la Universidad Nacional de Tucumán). Curaduría de feria para Fulana Galería para arteba, 2021. “Pulsiones Titubeantes”, Galería Gachi Prieto, 2022, Buenos Aires. “No es sobre el wow es sobre el aww”, Consultorio, Noviembre, 2022, San Miguel de Tucumán. “Residencia para curadores” del Proyecto URRA durante enero de 2023. Actualmente se encarga del área de producción/gestión de Bienal Sur (Universidad Tres de Febrero) en la sede de Tucumán.</p>
        </div>

        <div>

          <a href="https://www.instagram.com/huoket/" target="_blank"><span>⁂ Javier Rodríguez</span></a>
          <p>Tucumán, Argentina, 1996. Vive y trabaja en San Miguel de Tucumán, Tucumán.
Licenciado en Artes Plásticas por la Universidad Nacional de Tucumán (2020). Es Artista, músico, gestor, se desempeña como coordinador y parte del equipo de la Residencia Raíces del Centro Cultural Policarpo en Catamarca y co-dirigie Consultorio, un espacio independiente para el arte actual en San Miguel de Tucumán. Participa en exposiciones individuales y colectivas desde 2018, su trabajo se exhibió en la Bienal de Arte Joven, el 109° Salón Nacional, Premio Itaú, entre otros.
Realizó múltiples residencias y programas de formación (Programa de Artistas UTDT - 2022; R.A.R.O., Ecotono, El Pasaje, Raíces, etc.) y ha sido becado por el Fondo Nacional de Las Artes en 2019 y por la Secretaría de Ciencia Arte e Innovación Tecnológica de la Universidad Nacional de Tucumán (2019-2020), tanto para investigar como para viajar a jornadas internacionales de investigación. www.huoket.works
</p>
        </div>

      </div>
    </div>

    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>


<style scoped>

.grid > div {
  margin: 15px;
}
span {
  font-weight: 600;
  font-size: 0.8rem;
}
p {
  font-size: 0.9rem;
  text-align: left;
}

img {
  margin: 5px;
  max-height: 70vh;

}

router-link:hover * {
  text-decoration: none;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}
.pos {
  position: relative;
  overflow: hidden;
  padding: 3vw;
  height: auto;
  align-items: flex-end;
  align-content: flex-start;
  margin-bottom: 3vh;
}


section {
  padding: 5vw 20vw;
  background-color: #ddd;
}


.pos:hover {

  cursor: pointer;
}
.pos:hover img{
  transform: scale(1.04);
}



h3, .light {
  color: white;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
  text-align: left;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.5rem;
}

.light {
  
  font-size: 8pt;
  line-height: 0.8rem;
}


</style>