import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Memoria from '../views/memoria_trastocada.vue'
import Caminos from '../views/caminos_sin_sombra.vue'
import Wow from '../views/wow_aww.vue'
import Estigma from '../views/estigma.vue'
import Parasite from '../views/parasite_selfie'
import Silueta from '../views/silueta_constelacion'
import Nos from '../views/nosotrxs'
import rifa from '../views/rifa'
 

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/memoria_trastocada',
    name: 'memoria',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Memoria
  },
  {
    path: '/wow_aww',
    name: 'wow',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Wow
  },
  {
    path: '/caminos_sin_sombra',
    name: 'caminos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Caminos
  },
  {
    path: '/estigma',
    name: 'estigma',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Estigma
  },
  {
    path: '/parasite_selfie',
    name: 'parasite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Parasite
  },
  {
    path: '/silueta_constelacion',
    name: 'silueta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Silueta
  },
  {
    path: '/nosotrxs',
    name: 'nos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Nos
  },
  {
    path: '/rifa',
    name: 'rifa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: rifa
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
