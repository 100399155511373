<template>
  <div class="home container">

    
    
    <div class="container grid">
      
      <hgroup>
        <img src="../assets/Bingo1.jpg" alt="">
        <img src="../assets/Bingo2.jpg" alt="">
        <hr>

        <h2>GRAN LOTERIA POR EL ARTE TUCUMANO</h2>
        <H4>Consultorio te necesita!</H4>

        <p style="text-align: center;">
          <br>

         
          Para poder continuar brindando a nuestra querida comunidad la calidad de trabajo a la que les tenemos acostumbrades, hemos invitado y recibido la generosa donación de obras de maravillosos artistas que apreciamos muchísimo y nos acompañan desde el primer día 💝
          <br>
          <br>

          El domingo 17 de marzo a las 19 hs les esperamos en la Sociedad Francesa (San Juan 751) junto con tus amigos, familia, vecines, invitá a todo el mundo! La entrada es libre y gratuita. ¡Vení a compartir unos traguitos en la muestra y podés colaborar con la gestión independiente!
          <br>          
          <br>          
          Este bingo a beneficio de Consultorio es una estrategia que proponemos como una forma de involucrar a la comunidad con el espacio y los artistas y a los artistas y al espacio con la comunidad. Pensando en la sostenibilidad en el tiempo, en formas alternativas de financiar nuestro trabajo. Creemos que es de vital importancia ante la coyuntura trabajar comunitaria y colectivamente para continuar la forma en la que venimos abriendo el espacio a la comunidad.
          <br>
          <br>
          <hr>
      


          
          <h2>RIFA NACIONAL</h2>

          <br>
          Para aquellas personas que no están en Tucumán pero aún así quieren colaborar con el proyecto y participar por la obra de alguno de estos artistas hemos dispuesto una rifa única para participar por el sorteo de una obra de Ale Mizrahi y una obra de Elian Chali. Con la compra de un número ya estás participando por ambas obras!
          <hr>
          <br>
          El costo de la rifa de <b>$5000</b> (cinco mil pesos argentinos)
          <br>
          Para participar realizá una transferencia con el monto correspondiente a la cantidad de números que quieras comprar a:
          <br>
          <br>
          ALIAS: consultorio.tuc
          <br>
          CVU: 0000168300000015883452
          <br>
          Titular: Jorge Javier Rodríguez
          <br>
          <br>
          Y luego envía el comprobante de pago a <a href="https://wa.me/5493816156087" target="_blank">este WhatsApp</a>
          o a consultorio.tuc@gmail.com
          <br>
          A partir de ahí te daremos a elegir tus numeros para el sorteo que se realizará el mismo día de la lotería (Domingo 17 de marzo) en frente de nuestra comunidad y a través de un vivo de Instagram en nuestra cuenta.
          <br>
          <br>
          <br>
          Gracias por acompañarnos!

      



        </p>
        
      </hgroup>
      
    </div>

    <div class="container" style="padding-bottom: 10vh;">

      <img src="../assets/prox.jpg" alt="">
      
      
      
    </div>
      
    
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>


<style scoped>

p {
  font-size: 0.9rem;
  text-align: left;
}

img {
  margin: 5px;
  max-height: 70vh;

}

router-link:hover * {
  text-decoration: none;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}
.pos {
  position: relative;
  overflow: hidden;
  padding: 3vw;
  height: auto;
  align-items: flex-end;
  align-content: flex-start;
  margin-bottom: 3vh;
}


section {
  padding: 5vw 20vw;
  background-color: #ddd;
}


.pos:hover {

  cursor: pointer;
}
.pos:hover img{
  transform: scale(1.04);
}



h3, .light {
  color: white;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
  text-align: left;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.5rem;
}

.light {
  
  font-size: 8pt;
  line-height: 0.8rem;
}


</style>