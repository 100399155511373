<template>
  <div class="home container">

    
    
    <div class="container grid">
      
      <hgroup>
        <h2>Estigma</h2>
        <span>Exposición individual de Vero Galván, curaduría de Luis María Rojas. Agosto 2022.</span>
      </hgroup>
      
    </div>

    <div class="container" style="padding-bottom: 10vh;">

      <img src="../assets/veroThumb.jpg" alt="">
      <img src="../assets/estigma1.jpg" alt="">
      <img src="../assets/estigma2.jpg" alt="">
      <img src="../assets/estigma3.jpg" alt="">
      <img src="../assets/estigma4.jpg" alt="">

      <p>
        <br>
        <br>
        ¿Cómo dejar en evidencia un paisaje y un pasado que se carga en el cuerpo?
        <br>
        <br>
        La estrategia de Verónica es hacerlo a través del cuerpo mismo, entendido éste como un punto de intersección donde se encuentran y entretejen tramas familiares, sociales, históricas, comunitarias y biográficas.
        <br>
        <br>
        
        “Estigma” es una marca que cargamos en nuestros cuerpos que nos recuerda un pasado de oprobio o socialmente oprobioso. Sin embargo, no es una marca autoinfligida, sino una carga social asignada a quienes han escapado de lo socialmente aceptado, de la norma, de lo reglamentado, cruzando la línea hacia lo que hemos consensuado en llamar patológico o perverso.
        <br>
        <br>
        
        La obra de Verónica parece estructurarse en este campo semántico, preguntándose sobre su pasado ¿De dónde vengo? ¿Qué me rodea? ¿Cuál es mi realidad? Para responderse nos presenta rituales que exorcizan esa marca y sus tensiones como una forma de reflexionar sobre el espacio que habita, su paisaje y su historia, como una forma tenderle la mano y amigarse con su pasado y su herencia, para no sentirse dislocada, desplazada.
        <br>
        <br>
        
        Pero “estigma”, también refiere a la polinización de las flores, a la posibilidad de germinación, de crecimiento y creación. Aquí es donde su cuerpo toma protagonismo para pensar en clave de acción y hablar sobre su realidad inmediata. Verónica pone en situación diferentes elementos de su entorno -como si de una polinización cruzada se tratase- para transfigurarlos, explotar y eclosionar sus sentidos planteando cruces, situaciones de tensión y contradicción que interpelan a una realidad que dejamos de percibir homogénea, clara y evidente.
        <br>
        <br>
        
        El cuidado amoroso de un jardín y de nuestros sueños se convierten en acciones y símbolos paralelos en una realidad que se desborda y enriquece. Acciones de apariencia absurda, sinsentidos, violencias discursivas y situaciones imposibles se superponen para abrirnos un espacio de reflexión sobre lo personal y lo político, sobre un cuerpo que se transforma en paisaje y un paisaje que se hace cuerpo, donde los signos hacen parte de su biografía, pero también forman parte de una memoria colectiva.
        <br>
        <br>


        <b>Luis María Rojas</b>

        
      </p>

      <img src="../assets/estigmaFlyer.jpg" alt="">
      <br>
      <br>
      
    </div>
      
    
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>


<style scoped>

p {
  font-size: 0.9rem;
  text-align: left;
}

img {
  margin: 5px;
  max-height: 70vh;

}

router-link:hover * {
  text-decoration: none;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}
.pos {
  position: relative;
  overflow: hidden;
  padding: 3vw;
  height: auto;
  align-items: flex-end;
  align-content: flex-start;
  margin-bottom: 3vh;
}


section {
  padding: 5vw 20vw;
  background-color: #ddd;
}


.pos:hover {

  cursor: pointer;
}
.pos:hover img{
  transform: scale(1.04);
}



h3, .light {
  color: white;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
  text-align: left;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.5rem;
}

.light {
  
  font-size: 8pt;
  line-height: 0.8rem;
}


</style>