<template>
  <div class="home container">

    
    
    <div class="container grid">
      
      <hgroup>
        <h2>Caminos sin sombra</h2>
        <span>Exposición individual de <a href="https://www.instagram.com/diegogelatti/" target="_blank">Diego Gelatti</a>. Texto por Luis Sebastián Sanabria. Diciembre de 2022.</span>
      </hgroup>
      
    </div>

    <div class="container" style="padding-bottom: 10vh;">

      <img src="../assets/caminos2.jpg" alt="">
      <img src="../assets/caminosTxt.png" alt="">
      <img src="../assets/caminos3.jpg" alt="">
      <img src="../assets/caminos4.jpg" alt="">
      <img src="../assets/caminos5.jpg" alt="">
      <img src="../assets/caminos1.jpg" alt="">
      <img src="../assets/caminosFlyer.png" alt="">
      

      
    </div>
      
    
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>


<style scoped>

p {
  font-size: 0.9rem;
  text-align: left;
}

img {
  padding-bottom: 20px;
  max-height: 80vh;
}

router-link:hover * {
  text-decoration: none;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}
.pos {
  position: relative;
  overflow: hidden;
  padding: 3vw;
  height: auto;
  align-items: flex-end;
  align-content: flex-start;
  margin-bottom: 3vh;
}


section {
  padding: 5vw 20vw;
  background-color: #ddd;
}


.pos:hover {

  cursor: pointer;
}
.pos:hover img{
  transform: scale(1.04);
}



h3, .light {
  color: white;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
  text-align: left;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.5rem;
}

.light {
  
  font-size: 8pt;
  line-height: 0.8rem;
}


</style>