<template>
  <div class="home container" style="margin-bottom: 15vh;">


    
    <div class="grid">

      <!-- 
      <router-link to="/memoria_trastocada">
      <div class="pos">
          <div class="overlay"></div>
          <h3>Ejercicios de memoria trastocada</h3>
          <p class="light">Taller a cargo de Julián Matta - Muestra colectiva. Mayo 2023</p>
          <img src="../assets/juliThumb.jpg" alt="">
        </div>      
      </router-link>

       -->
      <router-link to="/rifa">
      <div class="pos">
          <div class="overlay"></div>
          <h3>GRAN LOTERIA POR EL ARTE TUCUMANO</h3>
          <p class="light">Consultorio te necesita!</p>
          <img src="../assets/Bingo1.jpg" alt="">
        </div>      
      </router-link>
      <router-link to="/parasite_selfie">
      <div class="pos">
          <div class="overlay"></div>
          <h3>Parasite selfie</h3>
          <p class="light">Exposición de Vic Papagni y Tobibienz. Texto por Nancy Rojas. Abril 2023.</p>
          <img src="../assets/parasiteThumb.jpg" alt="">
        </div>      
      </router-link>
      
        
      <router-link to="/caminos_sin_sombra">
        <div class="pos">
          <div class="overlay"></div>
          <h3>Caminos sin sombra</h3>
          <p class="light">Exposición individual de Diego Gelatti. Texto por Luis Sebastián Sanabria. Diciembre de 2022.</p>
          <img src="../assets/caminosThumb.jpg" alt="">
        </div>
      </router-link>
      
      

    </div>

    
    <div class="grid">
      
      
      <router-link to="/wow_aww">
      <div class="pos">
          <div class="overlay"></div>
          <h3>No es sobre el wow es sobre el aww</h3>
          <p class="light">Curaduría de Rocío Rivadeneyra. Participan: Florencia Méttola, Nando Migueles, Natalia Segré, Rolo Juárez, Sofia Casadey. Noviembre 2022.</p>
          <img src="../assets/wow10.jpg" alt="">
        </div>
      </router-link>
      
      
      <router-link to="/silueta_constelacion">
      <div class="pos">
          <div class="overlay"></div>
          <h3>La silueta de una constelación es inexistente</h3>
          <p class="light">Exposición de Ce Luján y Omar Jury, curaduría de Diego Gelatti. Septiembre 2022.</p>
          <img src="../assets/siluetaThumb.jpg" alt="">
        </div>      
      </router-link>
        

        
      <router-link to="/estigma">
        <div class="pos">
          <div class="overlay"></div>
          <h3>Estigma</h3>
          <p class="light">Exposición individual de Vero Galván, curaduría de Luis María Rojas. Agosto 2022.</p>
          <img src="../assets/veroThumb.jpg" alt="">
        </div>
      </router-link>
      

    </div>

    
      <hr style="padding-bottom: 2vw;">
      <i class="bi-instagram"></i>
      <a href="https://www.instagram.com/consultorio___" target="_blank">Instagram</a>
 
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>


<style scoped>



.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}
.pos {
  position: relative;
  overflow: hidden;
  padding: 3vw;
  height: 40vh;
  align-items: flex-end;
  align-content: flex-start;
  margin-bottom: 3vh;
}


section {
  padding: 5vw 20vw;
  background-color: #ddd;
}

img{
  position: absolute;
  top: 0;
  
  left: 0;
  
  object-fit: cover;
  z-index: -2;
  transition: all 0.5s;
  max-width: 120%;
  min-height: 100%;
  
}

.pos:hover {

  cursor: pointer;
}
.pos:hover img{
  transform: scale(1.04);
}



i {
 color: #ffca1b;
 padding-right: 1vw;
}


h3, .light {
  color: white;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
  text-align: left;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.5rem;
}

.light {
  
  font-size: 8pt;
  line-height: 0.8rem;
}


</style>