<template>
  <div class="home container">

    <h1>Memoria</h1>
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>


<style scoped>

router

router-link:hover * {
  text-decoration: none;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}
.pos {
  position: relative;
  overflow: hidden;
  padding: 3vw;
  height: auto;
  align-items: flex-end;
  align-content: flex-start;
  margin-bottom: 3vh;
}


section {
  padding: 5vw 20vw;
  background-color: #ddd;
}

img{
  position: absolute;
  top: 0;
  
  left: 0;
  
  object-fit: cover;
  z-index: -2;
  transition: all 0.5s;
  max-width: 120%;
  
}

.pos:hover {

  cursor: pointer;
}
.pos:hover img{
  transform: scale(1.04);
}



i {
 color: #ffca1b;
 padding-right: 1vw;
}


h3, .light {
  color: white;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
  text-align: left;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.5rem;
}

.light {
  
  font-size: 8pt;
  line-height: 0.8rem;
}


</style>