<template>
  <nav>
    
    <div class="header container">
        <!-- <img class="logo" src="./assets/logo.jpg" alt="Logo Consultorio" > -->
        <router-link to="/"><h5>Consultorio</h5></router-link>
        <div class="grow"></div>
        <router-link to="/nosotrxs"><span class="nos">Nosotrxs</span></router-link>
    </div>
    <!--<router-link to="/">Home</router-link> 
    <router-link to="/about">About</router-link> -->
  </nav>
  <router-view/>

  <div class="main">
    <div class="chiqui"></div>
    <div class="grow"></div>
    <div class="chiqui"></div>
  </div>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header {
  display: flex;
}
.grow {
  flex: 1 1 auto
}
.nos {
  font-size: 0.9rem;
  font-weight: 500;
  font-style: italic;
}
.header {
  display: flex;
  align-items: baseline;
}

.logo {
  width: 3vw;
  height: 3vw;
  aspect-ratio: 1 / 1 !important;
  border-radius: 5vw;
  margin-bottom: 5vw;
  margin-right: 1vw;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
