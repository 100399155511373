<template>
  <div class="home container">

    
    
    <div class="container grid">
      
      <hgroup>
        <h2>Parasite Selfie</h2>
        <span>Exposición de <a href="https://www.instagram.com/vicpapagni/" target="_blank">Vic Papagni</a> y <a href="https://www.instagram.com/tobibienz/" target="
          ">Tobibienz</a>. Texto por <a href="https://www.instagram.com/nancyvred/" target="_blank">Nancy Rojas</a>. <br>Abril 2023.</span>
      </hgroup>
      
    </div>

    <div class="container" style="padding-bottom: 10vh;">

      <img src="../assets/parasite2.jpg" alt="">
      <img src="../assets/parasite3.jpg" alt="">
      <img src="../assets/parasite4.jpg" alt="">

      <br>
      <br>
      <i style="text-align: center; letter-spacing: 0.2rem;">
        Texto de sala:
      </i>

      
      <h4>Parafernalia ilusoria para una manada sicalíptica: el magnetismo de las bichas</h4>
      
      “(…) nos necesitamos recíprocamente en colaboraciones y combinaciones inesperadas”.
      <br>
      <br>
      <i>-Donna Haraway</i>
      <br>
      <hr>
      <br>
      
      <p>
        El amor hacia las cucarachas podría ser procesado como el enamoramiento platónico de los humanos con las palomas si éstas, como tantas otras especies, no hubieran sido condenadas al desprecio fomentado por el desconocimiento de sus antiguas historias de devenir en el mundo. En este sentido, si el amor se nutriera de magnetismos imprevistos y de sinergias irracionales, contrarias a la lógica de la productividad, entonces no hubiéramos dudado en encontrar belleza en la biodiversidad. Es esta, la utopía que se pone en escena en el proyecto Parasite Selfie - Homecoming de Vic Papagni y Tobibienz. A través de una producción conjunta desarrollada en el marco de distintas residencias para artistas —Yet To Com.(e), virtual, CHELA (Centro Hipermediático de Experimentación Latinoamericano), Buenos Aires, y Residencia La Rural, Tucumán— su propuesta expone un relato donde seres humanos, insectos y objetos se retroalimentan en la aproximación y en la empatía, en tanto figuras capaces de materializar semejanzas abyectas e inadmisibles.
        
      </p>
        <br>
        <img src="../assets/parasite5.jpg" alt="">
        <br>
        <br>
        
      <p>
        La historia comenzó en un otoño de 2019, en medio de las protestas de Hong Kong, cuando Tobibienz se enamoró de una cucaracha que estaba deambulando en el techo de un rascacielos. Su instinto comunal lo condujo a trasladarla a su casa para entablar una vida juntos; es decir, con ella y con las numerosas bichas de su especie que desde entonces han ido multiplicándose. En enero de 2021, Tobibienz empezó a trabajar con Victoria Papagni. Artista que, abducida por el nativismo digital, navega entre la tecnología, la auto-representación liminal y el manifiesto sexual, generando tráficos sugestivos en los devenires de la diáspora prostética de la cultura actual.
        <br>
        <br>
        Son dos procesos de producción que se potencian en la extrañeza y en la sensualidad, en el acople y en la distorsión como semblanza de la cualidad performativa de sus prácticas.
        <br>
        <br>
        En Parasite Selfie - Homecoming Tobibienz y Vic Papagni apuestan a una metafísica que insiste en la necesidad del diseño de sí, dejando que la más queer de todas, la naturaleza (dixit Brigitte Baptiste), sea la que autorice sus propios desdoblamientos.
        <br>
      </p>
      
      <img src="../assets/parasite6.jpg" alt="">
      <img src="../assets/parasite7.jpg" alt="">

      <p>
        <br>
        En las piezas que componen el proyecto se centran en una especie particular de cucaracha: la blaptica dubia, originaria de las Yungas tucumanas. Con ella, elaboran una secuencia narrativa que no deja al margen los gérmenes historiográficos del bioarte sino que los incorpora. Se trata de una obra que, en el cruce entre el arte, ciencia y tecnología, pone en foco una metáfora paralizadora del sentido de la repulsión. Más bien, introduce la expresión de lo abyecto en el cuerpo como continente para consumar esa simpatía entre humano y no humano, convirtiendo a esa relación en una topografía rosa: un ejercicio de conversación infinita con la historia de los usos de este color. 
        <br>
        <br>
        La apuesta es a un desplazamiento de la belleza por el magnetismo, impulsado por la pulsión ciborg que se hace omnipresente cuando cuajan los imaginarios paradisíacos. Ahí es donde los seres se tocan ilusoriamente, se desean y se veneran en una mutualidad inmanente al paraíso de las deidades potenciales: de las bichas.
        <br>
        <br>
        
        <b>Nancy Rojas</b>
        
        
      </p>
      <img src="../assets/parasite8.jpg" alt="">
      <img src="../assets/parasite1.jpg" alt="">
      <img src="../assets/parasiteFlyer.jpg" alt="">
    </div>
      
    
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>


<style scoped>

p {
  font-size: 0.9rem;
  text-align: left;
}

img {
  padding-bottom: 10px;
  max-height: 70vh;
}

router-link:hover * {
  text-decoration: none;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}
.pos {
  position: relative;
  overflow: hidden;
  padding: 3vw;
  height: auto;
  align-items: flex-end;
  align-content: flex-start;
  margin-bottom: 3vh;
}


section {
  padding: 5vw 20vw;
  background-color: #ddd;
}


.pos:hover {

  cursor: pointer;
}
.pos:hover img{
  transform: scale(1.04);
}



h3, .light {
  color: white;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
  text-align: left;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.5rem;
}

.light {
  
  font-size: 8pt;
  line-height: 0.8rem;
}


</style>