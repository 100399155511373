import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import '@picocss/pico/css/pico.min.css';



const firebaseConfig = {
  apiKey: "AIzaSyCArTN3SiXs7AqcNyj7B10HzY0aDZYcrWA",
  authDomain: "consultorio-31b7f.firebaseapp.com",
  projectId: "consultorio-31b7f",
  storageBucket: "consultorio-31b7f.appspot.com",
  messagingSenderId: "772357727084",
  appId: "1:772357727084:web:70de1856da5889f7f90949",
  measurementId: "G-D9E7WE3EGB"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

createApp(App).use(router).mount('#app')
