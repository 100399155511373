<template>
  <div class="home container">

    
    
    <div class="container grid">
      
      <hgroup>
        <h2>La silueta de una constelación es inexistente</h2>
        <span>Exposición de Ce Luján y Omar Jury, curaduría de Diego Gelatti. Septiembre 2022.</span>
      </hgroup>
      
    </div>

    <div class="container" style="padding-bottom: 10vh;">

      <img src="../assets/silueta1.jpg" alt="">
      <img src="../assets/silueta2.jpg" alt="">

      <p>
        <br>
        <br>
        <i>
          Texto de sala:
        </i>
        <br>
        <br>
        Reconocemos las cosas en gran medida a partir de sus contornos. Si algo no los tiene —como las gotas de agua cuando se evaporan— quizás no sea posible evaluarlo solamente con la visión. En los cimientos de la confianza encontramos el sentido de la vista. Percibimos la superficie de un lago como un plano. Sin embargo, intuimos que hay algo debajo, aunque no conozcamos su contenido, ni su profundidad. 
        <br>
        <br>
        
        ¿Las gotas de agua dejan de tener un contorno cuando se evaporan? 
        <br>
        <br>

        “En realidad, la idea nunca fue preguntarse si algo existía o no. Cuando he escrito el libro, quería pensar en algunas fuerzas y en cómo hay cosas que aparecen sin que nadie las pida. Durante quince meses he hablado con C. Una vez, cuando estábamos borrachos en una vereda, me ha confesado saber que algo secreto nos impulsa a todos, y que nuestro único deber consiste en conducir eso —que no sabemos bien qué es— hacia un lugar, a elevarlo, a usarlo de estandarte. ¿Cómo iba a llevar como estandarte algo que no puedo identificar? Sin darme cuenta, esa epifanía ajena fue cambiando la dirección de mis pensamientos, y, por lo tanto, la del libro— tanto así, que he llegado a pensar que un dibujo es lo contrario a un lago. Sin embargo, no van a encontrar esas aseveraciones aquí. Todas las ideas arriesgadas han quedado afuera, la física es incapaz de justificarlas.”
        <br>
        <br>


        <b>Diego Gelatti</b>

        
      </p>

      <img src="../assets/silueta3.jpg" alt="">
      <img src="../assets/silueta4.jpg" alt="">
      <img src="../assets/silueta5.jpg" alt="">
      <img src="../assets/silueta6.jpg" alt="">
      <img src="../assets/silueta7.jpg" alt="">
      <img src="../assets/silueta8.jpg" alt="">
      <img src="../assets/silueta9.jpg" alt="">
      <img src="../assets/siluetaFlyer.jpg" alt="">
      



      <br>
      <br>
      
    </div>
      
    
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>


<style scoped>

p {
  font-size: 0.9rem;
  text-align: left;
}

img {
  margin: 5px;
  max-height: 70vh;

}

router-link:hover * {
  text-decoration: none;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}
.pos {
  position: relative;
  overflow: hidden;
  padding: 3vw;
  height: auto;
  align-items: flex-end;
  align-content: flex-start;
  margin-bottom: 3vh;
}


section {
  padding: 5vw 20vw;
  background-color: #ddd;
}


.pos:hover {

  cursor: pointer;
}
.pos:hover img{
  transform: scale(1.04);
}



h3, .light {
  color: white;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
  text-align: left;
  margin-bottom: 10px;
}

h3 {
  line-height: 1.5rem;
}

.light {
  
  font-size: 8pt;
  line-height: 0.8rem;
}


</style>